*::-webkit-scrollbar {
    width: 6px;
    height: 6px;
}

*::-webkit-scrollbar-track {
    /* box-shadow: inset 0 0 4px 4px #686868; */
    border: solid 6px #424242;

    border-radius: 15px;
}

*::-webkit-scrollbar-thumb {
    /* box-shadow: inset 0 0 4px 4px #87B4EA; */
    border: solid 6px #87b4ea;

    border-radius: 15px;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type='number'] {
    appearance: textfield;
}

a {
    color: inherit;
}

.loader {
    font-size: 10px;
    width: 1em;
    height: 1em;
    border-radius: 50%;
    position: relative;
    text-indent: -9999em;
    animation: spin 1.1s infinite ease;
    transform: translateZ(0);
}

.drop_area {
    position: static !important;
    height: 100%;
}

@keyframes spin {
    0%,
    100% {
        box-shadow:
            0em -2.6em 0em 0em #ffffff,
            1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2),
            2.5em 0em 0 0em rgba(255, 255, 255, 0.2),
            1.75em 1.75em 0 0em rgba(255, 255, 255, 0.2),
            0em 2.5em 0 0em rgba(255, 255, 255, 0.2),
            -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.2),
            -2.6em 0em 0 0em rgba(255, 255, 255, 0.5),
            -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.7);
    }
    12.5% {
        box-shadow:
            0em -2.6em 0em 0em rgba(255, 255, 255, 0.7),
            1.8em -1.8em 0 0em #ffffff,
            2.5em 0em 0 0em rgba(255, 255, 255, 0.2),
            1.75em 1.75em 0 0em rgba(255, 255, 255, 0.2),
            0em 2.5em 0 0em rgba(255, 255, 255, 0.2),
            -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.2),
            -2.6em 0em 0 0em rgba(255, 255, 255, 0.2),
            -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.5);
    }
    25% {
        box-shadow:
            0em -2.6em 0em 0em rgba(255, 255, 255, 0.5),
            1.8em -1.8em 0 0em rgba(255, 255, 255, 0.7),
            2.5em 0em 0 0em #ffffff,
            1.75em 1.75em 0 0em rgba(255, 255, 255, 0.2),
            0em 2.5em 0 0em rgba(255, 255, 255, 0.2),
            -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.2),
            -2.6em 0em 0 0em rgba(255, 255, 255, 0.2),
            -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2);
    }
    37.5% {
        box-shadow:
            0em -2.6em 0em 0em rgba(255, 255, 255, 0.2),
            1.8em -1.8em 0 0em rgba(255, 255, 255, 0.5),
            2.5em 0em 0 0em rgba(255, 255, 255, 0.7),
            1.75em 1.75em 0 0em #ffffff,
            0em 2.5em 0 0em rgba(255, 255, 255, 0.2),
            -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.2),
            -2.6em 0em 0 0em rgba(255, 255, 255, 0.2),
            -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2);
    }
    50% {
        box-shadow:
            0em -2.6em 0em 0em rgba(255, 255, 255, 0.2),
            1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2),
            2.5em 0em 0 0em rgba(255, 255, 255, 0.5),
            1.75em 1.75em 0 0em rgba(255, 255, 255, 0.7),
            0em 2.5em 0 0em #ffffff,
            -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.2),
            -2.6em 0em 0 0em rgba(255, 255, 255, 0.2),
            -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2);
    }
    62.5% {
        box-shadow:
            0em -2.6em 0em 0em rgba(255, 255, 255, 0.2),
            1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2),
            2.5em 0em 0 0em rgba(255, 255, 255, 0.2),
            1.75em 1.75em 0 0em rgba(255, 255, 255, 0.5),
            0em 2.5em 0 0em rgba(255, 255, 255, 0.7),
            -1.8em 1.8em 0 0em #ffffff,
            -2.6em 0em 0 0em rgba(255, 255, 255, 0.2),
            -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2);
    }
    75% {
        box-shadow:
            0em -2.6em 0em 0em rgba(255, 255, 255, 0.2),
            1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2),
            2.5em 0em 0 0em rgba(255, 255, 255, 0.2),
            1.75em 1.75em 0 0em rgba(255, 255, 255, 0.2),
            0em 2.5em 0 0em rgba(255, 255, 255, 0.5),
            -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.7),
            -2.6em 0em 0 0em #ffffff,
            -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2);
    }
    87.5% {
        box-shadow:
            0em -2.6em 0em 0em rgba(255, 255, 255, 0.2),
            1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2),
            2.5em 0em 0 0em rgba(255, 255, 255, 0.2),
            1.75em 1.75em 0 0em rgba(255, 255, 255, 0.2),
            0em 2.5em 0 0em rgba(255, 255, 255, 0.2),
            -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.5),
            -2.6em 0em 0 0em rgba(255, 255, 255, 0.7),
            -1.8em -1.8em 0 0em #ffffff;
    }
}
